import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Endpoints } from "../../repository/Endpoints";
import { GetMaker } from "../../repository/RequestMaker";
import { Translations } from "../../repository/Translations";
import { Spinner } from "react-bootstrap";
import { Language } from "./Language";
import Logo from "../../utils/sharedComponents/Logo";
import ImprintFooter from "../../utils/sharedComponents/ImprintFooter";
const Home = () => {
  // inits
  const history = useHistory();
  const [languageAvailable, setLanguageAvailable] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [langToggled, setLangToggled] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(false);
  // logics
  const onNext = (e) => {
    e.preventDefault();
    if (langToggled && cookieChecked) {
      history.push({
        pathname: "/userdata",
      });
    }
    if (!langToggled) {
      alert(
        "Bitte wählen Sie die gewünschte Sprache // Please select the requested language"
      );
    }
    if (!cookieChecked) {
      alert(
        'Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen ...".'
      );
    }
  };

  const setLanguage = (id) => {
    setLangToggled(true);
    console.log("selected language", id);
    id = id.split(" ");
    localStorage.setItem("language_id", id[0]);
    localStorage.setItem("language", id[1]);
    Translations(id[1], Language);
  };

  useEffect(() => {
    localStorage.clear();
    window.scrollTo(0, 0);
    // localStorage.setItem("tenant_id", "52eef02e-65c1-4c44-bb28-e6f8993fdd2e");//Steam & Power
    localStorage.setItem("tenant_id", "00abcb6a-b13d-457b-b559-9109af207eac"); //Gelsenkirchen

    GetMaker(Endpoints.list_languages).then((res) => {
      //console.log(res.data);
      setLanguages(res.data);
      setLanguageAvailable(true);
    });
  }, []);

  useLayoutEffect(() => {
    Translations("de", Language);
  }, []);
  // render
  return (
    <>
      <div className="bg-mine">
        <h1 className="display-4" id="title_text">
          Sprache auswählen
        </h1>

        <p id="subtitle_text">
          Bitte wählen Sie die gewünschte Sprache, bevor Sie fortfahren.
        </p>
        <small className="form-text text-muted">
          Please select your preferred language before proceeding.
        </small>
        <p>
          <b>The CoW-training is only available in German language!</b>{" "}
        </p>
        <hr className="my-4" />
        <div>
          <div className="input-group mb-3">
            <span
              className="input-group-text"
              id="basic-addon3"
              id="language_text"
            >
              Sprache
            </span>
            {languageAvailable ? (
              <select
                className="form-control"
                id="language_select"
                onChange={(e) => setLanguage(e.currentTarget.value)}
              >
                <option disabled selected>
                  Bitte wählen Sie die gewünschte Sprache // Please select the
                  requested language
                </option>
                {languages
                  .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
                  .map((data, key) => {
                    return (
                      <option key={key} value={data.id + " " + data.lang}>
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            ) : (
              <Spinner animation="grow" />
            )}
          </div>

          <br />

          <div className="d-flex flex-row">
            {/* <input
              className="custom-control-input"
              type="checkbox"
              name="cookieMaker"
              id="cookieMaker"
              onChange={(e) => setCookieChecked(e.currentTarget.checked)}
            /> */}

            <label className="toggle-control">
              <input
                type="checkbox"
                onChange={(e) => setCookieChecked(e.currentTarget.checked)}
              />
              <span className="control"></span>
            </label>
            <span className="margin_left"></span>
            <label
              className="custom-control-label"
              id="privacy_agree_text"
              for="cookieMaker"
              style={{ marginLeft: "30px", marginTop: "-3px" }}
            >
              Ich habe die Datenschutzhinweise & Nutzungsbedingungen gelesen und
              bin damit einverstanden.
            </label>
          </div>
        </div>
        <div>
          <div className="margin_top"></div>
          <button
            // className="btn btn-outline-dark btn-lg btn-round"
            className="btn-round"
            id="next_button"
            onClick={onNext}
          >
            <i class="fa fa-forward" aria-hidden="true"></i>
          </button>
          <p className="form-text text-muted" id="next_button_statement">
            Bitte klicken Sie zum Fortfahren auf das Kontrollkästchen neben der
            Erklärung "Ich habe die Datenschutzhinweise & Nutzungsbedingungen
            gelesen ...".
          </p>
        </div>

        <a href="./imprint">
          <ImprintFooter>
            {" "}
            <span id="imprint_button">Impressum</span>{" "}
          </ImprintFooter>
        </a>
      </div>
    </>
  );
};

export default Home;
