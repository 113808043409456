import React, { useState, useEffect } from "react";
import { Endpoints } from "../../repository/Endpoints";
import { Base64RequestMaker, PostMaker } from "../../repository/RequestMaker";
import { Spinner } from "react-bootstrap";
import { saveAs } from "file-saver";
import Logout from "../../utils/sharedComponents/Logout";

const Certificate = () => {
  //inits
  const [isLoading, setIsLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState("");
  const enrollment_id = localStorage.getItem("enrollment_id");
  const tenant_id = localStorage.getItem("tenant_id");
  const [cc, setCc] = useState("");
  const profile_id = localStorage.getItem("profile_id");

  //logics
  const b64toBlob = (b64Data, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });
    return blob;
  };

  const onNext = (e) => {
    e.preventDefault();
    const username = localStorage.getItem("username");
    const usercompany = localStorage.getItem("usercompany");
    const kindaUnique = Date.now();
    saveAs(pdfFile, username + "_" + usercompany + "_" + kindaUnique + ".pdf");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    PostMaker(
      {
        enrollment_id: enrollment_id,
        tenant_id: tenant_id,
        cc: "",
        profile_id: profile_id,
      },
      Endpoints.certificate
    ).then((res) => {
      const blob = b64toBlob(res);
      const blobUrl = URL.createObjectURL(blob);
      setPdfFile(blobUrl);
      setIsLoading(false);
    });
  }, []);

  const sendCertificateCC = () => {
    if (cc !== "") {
      PostMaker(
        {
          enrollment_id: enrollment_id,
          tenant_id: tenant_id,
          cc: cc,
          profile_id: profile_id,
        },
        Endpoints.certificate
      ).then((res) => {
        alert("Zertifikat geliefert an " + cc);
      });
    } else{
      alert('Email form empty');
    }
  };
  return (
    <>
      <div className="bg-mine">
        {isLoading ? (
          <Spinner animation="grow" />
        ) : (
          <>
            <div>
              <h1 className="display-4" id="title_text">
                Herzlichen Glückwunsch
              </h1>
              <p id="subtitle_text">
                Vielen Dank für Ihre Teilnahme an der Unterweisung.
              </p>{" "}
              <p id="subtitle_text">
                Das Zertifikat wird auch an Ihre E-Mail Adresse gesendet.
              </p>
              <hr className="my-4" />
            </div>
            <div style={{ height: "80vh" }}>
              <iframe
                id="pdf-js-viewer"
                src={"lib/web/viewer.html?file=" + pdfFile}
                title="webviewer"
                frameBorder="0"
                width="100%"
                height="100%"
                style={{ borderRadius: "20px" }}
              ></iframe>
            </div>
            <div className="margin_top"></div>
            <div className="d-flex justify-content-end">
              <button className="btn-round" onClick={onNext}>
                <i class="fa fa-download" aria-hidden="true"></i>
              </button>
            </div>
            <hr className="my-4" />
            <p id="subtitle_text">
              Bei Bedarf fügen Sie eine weiter E-Mail Adresse hinzu
            </p>
            <div className="input-group mb-3">
              <input
                value={cc}
                type="text"
                className="form-control input"
                placeholder="email address"
                id="username"
                onChange={(e) => setCc(e.target.value)}
              />
            </div>
            <small className="form-text text-muted" id="username_muted">
              Bitte überprüfen Sie die E-Mail Adresse. An diese E-Mail Adresse
              versenden wir im Anschluss das Zertifikat.
            </small>
            <div className="margin_top"></div>
            <button
              name="previous_field"
              //className="btn btn-outline-dark"
              className="btn-smol btn-media"
              type="button"
              onClick={sendCertificateCC}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
            <div style={{ height: "60px" }}></div>
          </>
        )}
      </div>
      <Logout />
    </>
  );
};

export default Certificate;
